import styled, { css } from "styled-components";

import { muiPalette } from "../theme";
import { baseButtonStyles } from "./Base";

export const disabledStyles = css`
  background-color: #dadae7;
  color: ${muiPalette.primary.main};
`;

export const primaryButtonStyles = css`
  ${baseButtonStyles}

  background-color: ${muiPalette.primary.main};
  color: white;

  &:hover {
    color: #b5b7d0;
  }

  &:disabled {
    ${disabledStyles}
  }
`;

export const PrimaryButton = styled.button`
  ${primaryButtonStyles}
`;
