import { useCanViewConfigManagementEntity } from "@src/Hooks/Permissions/configManagements";
import { useCanViewCoreManager } from "@src/Hooks/Permissions/coreManager";
import { useCanViewTroubleshooting } from "@src/Hooks/Permissions/troubleshooting";

export function useMPNNavPerms() {
  const { allowed: canViewConfigManagement } = useCanViewConfigManagementEntity();
  const { allowed: canViewTroubleshooting } = useCanViewTroubleshooting();
  const { allowed: canViewCoreManager } = useCanViewCoreManager();

  const queriesSettled =
    canViewConfigManagement !== null &&
    canViewTroubleshooting !== null &&
    canViewCoreManager !== null;

  return {
    queriesSettled,
    canViewConfigManagement,
    canViewTroubleshooting,
    canViewCoreManager
  };
}
