import { useRef } from "react";
import styled, { css } from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import AdminDashboard from "@img/admin-dashboard.svg";
import DownArrow from "@img/arrow_light_down.svg";
import UpArrow from "@img/arrow_light_up.svg";
import Dashboards from "@img/dashboards.svg";
import MECDashboard from "@img/mec-dashboard.svg";
import ServicesDashboard from "@img/services.svg";
import MPNDashboard from "@img/sim-card.svg";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { DisableableNavLink } from "./Components/Navigation/DisableableNavLink";
import { HeaderClickable, HeaderItem, HeaderLabel, UserMenu } from "./Header/HeaderStyles";
import { useDefaultOrg } from "./Hooks/defaultOrg";
import { useCanReadAdminDashboard } from "./Hooks/Permissions/adminDashboard";
import { useMECDashboard } from "./Hooks/Permissions/mecDashboard";
import { useMPNDashboard } from "./Hooks/Permissions/mpnDashboard";
import { useServicesDashboard } from "./Hooks/servicesDashboard";
import { useToggle } from "./Hooks/toggle";
import { useCurrentDashboard } from "./Hooks/useCurrentDashboard";

const dashboardIconStyles = css`
  height: 50px;
`;

const DashboardsMenuWrapper = styled(UserMenu)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 4px;
  justify-items: center;
`;

const ServicesDashboardIcon = styled(ServicesDashboard)`
  ${dashboardIconStyles}
`;
const AdminDashboardIcon = styled(AdminDashboard)`
  ${dashboardIconStyles}
`;
const MPNDashboardIcon = styled(MPNDashboard)`
  ${dashboardIconStyles}
  width: 42px;
`;
const MECDashboardIcon = styled(MECDashboard)`
  ${dashboardIconStyles}
`;

const DashboardIconLink = styled(DisableableNavLink)<{ $active: boolean }>`
  display: flex;
  width: 80px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  padding: 8px;
  border-radius: 4px;

  color: ${({ theme, $active }) => $active && theme.primary};
  background-color: ${({ theme, $active }) => $active && theme.backgroundLight};
  & svg {
    fill: ${({ theme, $active }) => $active && theme.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.backgroundLight};
    & svg {
      fill: ${({ theme }) => theme.primary};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.grey};
    background-color: white;
    border: 2px solid ${({ theme }) => theme.grey};
    & svg {
      fill: ${({ theme }) => theme.grey};
    }
  }
`;

export function DashboardsMenu() {
  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const {
    state: isOpenDashboards,
    toggle: toggleMenuDashboards,
    off: closeMenuDashboards
  } = useToggle();

  const dashboardsMenu = useRef();
  useOnClickOutside(dashboardsMenu, closeMenuDashboards);

  const { isServices, isMPN, isAdminDashboard, isMEC } = useCurrentDashboard();
  const { allowed: allowAdminDash } = useCanReadAdminDashboard();
  const { allowed: allowMPN } = useMPNDashboard();
  const { allowed: showMEC } = useMECDashboard();
  const { allowed: allowServices } = useServicesDashboard();

  const org = orgCtx || defaultOrg;

  const disableServicesDashboard = !allowServices || !org;
  const disableMPNDashboard = !allowMPN || !org;

  return (
    <HeaderItem ref={dashboardsMenu}>
      <HeaderClickable onClick={toggleMenuDashboards}>
        <Dashboards height={22} width={22} />
        <HeaderLabel>Dashboards</HeaderLabel>
        {isOpenDashboards ? (
          <UpArrow height={14} width={14} />
        ) : (
          <DownArrow height={14} width={14} />
        )}
      </HeaderClickable>
      {isOpenDashboards && (
        <DashboardsMenuWrapper onClick={closeMenuDashboards}>
          <DashboardIconLink
            to={`/app/${org}/services`}
            $active={isServices}
            disabled={disableServicesDashboard}
          >
            <ServicesDashboardIcon />
            <span>Services Dashboard</span>
          </DashboardIconLink>
          <DashboardIconLink to={`/app/${org}/mpn`} $active={isMPN} disabled={disableMPNDashboard}>
            <MPNDashboardIcon />
            <span>LTE/5G Networks</span>
          </DashboardIconLink>
          <DashboardIconLink
            to={`/app/${org}/admin`}
            $active={isAdminDashboard}
            disabled={!allowAdminDash}
          >
            <AdminDashboardIcon />
            <span>Admin Dashboard</span>
          </DashboardIconLink>
          {showMEC && (
            <DashboardIconLink to={`/app/${org}/mec`} $active={isMEC}>
              <MECDashboardIcon />
              <span>MEC</span>
              <span>Manager</span>
            </DashboardIconLink>
          )}
        </DashboardsMenuWrapper>
      )}
    </HeaderItem>
  );
}
