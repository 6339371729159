import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanViewCoreManager() {
  return usePermissionCheck({
    objectType: Namespace.ObjectPolicy,
    objectId: CORE_MANAGER_OBJECT_POLICY_ID,
    relation: Permit.Read
  });
}

export function useCanCreateCoreManager(orgId: string) {
  return usePermissionCheck({
    objectType: Namespace.Org,
    objectId: orgId,
    relation: Permit.CreateCoreManager
  });
}

export function useCanEditCoreManager(id: string) {
  return usePermissionCheck({
    objectType: Namespace.CoreManager,
    objectId: id,
    relation: Permit.Write
  });
}

export function useCanDeleteCoreManager(id: string) {
  return usePermissionCheck({
    objectType: Namespace.CoreManager,
    objectId: id,
    relation: Permit.Delete
  });
}
