import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useMECDashboard } from "@src/Hooks/Permissions/mecDashboard";
import { useMPNDashboard } from "@src/Hooks/Permissions/mpnDashboard";
import { useCanReadSim } from "@src/Hooks/Permissions/sim";

interface LandingPermissionsProps {
  networkId: string;
  coreId: string;
}

export function useLandingPermissions({
  networkId,
  coreId
}: LandingPermissionsProps): LandingPermissionsResult {
  const orgCtx = useOrgCtx();

  const { allowed: canReadSIM } = useCanReadSim(coreId);
  const { allowed: allowedMPN } = useMPNDashboard();
  const { allowed: allowedMEC } = useMECDashboard();

  return {
    appManager: {
      allowed: allowedMEC,
      url: appManagerUrl(orgCtx)
    },
    infrastructureManager: {
      allowed: allowedMEC,
      url: infraManagerUrl(orgCtx)
    },
    mpnManager: {
      allowed: allowedMPN,
      url: mpnManagerUrl(orgCtx, networkId)
    },
    simCardsTable: {
      allowed: canReadSIM && allowedMPN,
      url: simCardsTableUrl(orgCtx, networkId, coreId)
    }
  };
}

export function appManagerUrl(org: string) {
  return `/app/${org}/mec/operate/deployed-apps`;
}
export function infraManagerUrl(org: string) {
  return `/app/${org}/mec/mec-sites/inventory`;
}
export function mpnManagerUrl(org: string, network: string) {
  return `/app/${org}/mpn/${network}/operate/overview`;
}
export function simCardsTableUrl(org: string, network: string, core: string) {
  return `/app/${org}/mpn/${network}/sim-cards/${core}/manage`;
}

export interface LandingPermission {
  allowed: boolean;
  url: string;
}

interface LandingPermissionsResult {
  appManager: LandingPermission;
  infrastructureManager: LandingPermission;
  mpnManager: LandingPermission;
  simCardsTable: LandingPermission;
}
