import styled, { css } from "styled-components";

import { Clickable } from "../Buttons/Clickable";

export const boxShadow = css`
  box-shadow: 0 1px 1px 0 rgba(204, 204, 204, 0.5);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

export const THead = styled.thead`
  height: 25px;
  background: ${props => props.theme.primary};
  font-size: 10px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.2px;
`;

const trHover = css`
  box-shadow: 0 0.5px 0.5px 0 rgba(204, 204, 204, 0.5);
  & td {
    background-color: ${({ theme }) => theme.primaryBackground};
    &:first-child {
      box-shadow: ${props => `inset 5px 0px 0px ${props.theme.primary}`};
    }
  }
`;

interface TrProps {
  clickable?: boolean;
  selected?: boolean;
  checked?: boolean;
}

export const Tr = styled.tr<TrProps>`
  ${boxShadow}
  height: 60px;
  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
        `
      : null}

  &:hover {
    ${trHover}
  }
`;

export const TBody = styled.tbody``;

export const Th = styled.th`
  padding-left: 25px;
  &:first-child {
    padding-left: 33px;
  }
  &:last-child {
    padding-right: 33px;
  }
`;

export const Td = styled.td`
  padding-left: 25px;
  &:last-child {
    padding-right: 20px;
  }
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  letter-spacing: 0.6px;
  border-style: solid;
  border-width: 0 0 1px;
  border-color: rgba(204, 204, 204, 0.5);
`;

const alternatingBackground = css`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.backgroundLight};
  }
  &:nth-child(odd) {
    background-color: white;
  }
`;

export const AlternatingTr = styled(Tr)`
  ${alternatingBackground}
`;

export const ExpandableTd = styled(Td)`
  &:first-child {
    padding: 0 16px;
  }
`;

export const ExpandedTr = styled.tr`
  ${boxShadow}
`;

export const ExpandedTd = styled(Td)`
  padding: 0;
  &:last-child {
    padding-right: 0px;
  }
  & td {
    border-width: 1px 0 0;
  }
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TableActionButton = styled(Clickable)`
  padding: 0;
`;
